import { ComponentType, ComponentClass } from "react";

interface ComponentEnhancer<TInner, TOutter> {
	(component: ComponentType<TInner>): ComponentClass<TOutter>;
}

export const compose = <TInner, TOuter>(
	...funcs: Function[]
): ComponentEnhancer<TInner, TOuter> =>
	funcs.reduce(
		(a, b) => (...args: unknown[]) => a(b(...args)),
		(arg: unknown) => arg
	) as ComponentEnhancer<TInner, TOuter>;
