import { ReactElement, ReactNode } from "react";
import "typeface-roboto";
import { Role } from "car-rental-management-shared";
import { useDispatch } from "react-redux";
import Api from "car-rental-management-api";
import { ApiState } from "../../store/state";
import { Api as ApiAction } from "../../store/actions/Api";
import { apiBaseUrl } from "../../config/paths";

export interface AuthorizedRenderProps {
	api: ApiState;
	access?: Role[];
	onUnauthorized(): void;
	children: ReactNode;
	fallback?: ReactNode;
}

export const AuthorizedRender = ({
	api,
	access,
	onUnauthorized,
	children,
	fallback
}: AuthorizedRenderProps): ReactElement | null => {
	const dispatch = useDispatch();

	if (api === false || (api && access && !access.includes(api.data.role))) {
		onUnauthorized();
		return null;
	} else if (api === null) {
		Api.checkCookie({ baseUrl: apiBaseUrl })
			.then(api => dispatch(ApiAction.set(api)))
			.catch(() => dispatch(ApiAction.set(false)));
		if (fallback) {
			return <>{fallback}</>;
		}
		return null;
	}
	return <>{children}</>;
};
