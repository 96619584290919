import { FC } from "react";
import { List } from "@material-ui/core";

export interface SideBarListProps {
	topDivider?: boolean;
	bottomDivider?: boolean;
}

export const SideBarList: FC<SideBarListProps> = ({ children }) => {
	return <List>{children}</List>;
};
