import { Reducer } from "redux";
import { VehiclesState } from "../state";
import { ActionType, SetVehiclesAction } from "../actions/types";

export const vehicles: Reducer<VehiclesState, SetVehiclesAction> = (
	state = null,
	action
) => {
	if (action.type === ActionType.SET_VEHICLES) {
		return action.payload;
	}
	return state;
};
