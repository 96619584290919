import { Reducer } from "redux";
import { UsersState } from "../state";
import { ActionType, SetUsersAction } from "../actions/types";

export const users: Reducer<UsersState, SetUsersAction> = (
	state = null,
	action
) => {
	if (action.type === ActionType.SET_USERS) {
		return action.payload;
	}
	return state;
};
