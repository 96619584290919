import { Reducer } from "redux";
import { WialonUnitState } from "../state";
import { ActionType, SetWialonUnitsAction } from "../actions/types";

export const wialonUnits: Reducer<WialonUnitState, SetWialonUnitsAction> = (
	state = null,
	action
) => {
	if (action.type === ActionType.SET_WIALON_UNIT) {
		return action.payload;
	}
	return state;
};
