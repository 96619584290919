import { FC } from "react";
import {
	withStyles,
	createStyles,
	Theme,
	WithStyles,
	Paper
} from "@material-ui/core";

/**
 *
 * To be used for pages.
 */
const PageBackgroundBase: FC<WithStyles<typeof styles>> = ({
	classes,
	children
}) => {
	return <Paper className={classes.body}>{children}</Paper>;
};

const styles = (theme: Theme) =>
	createStyles({
		body: {
			padding: theme.spacing(2),
			margin: theme.spacing(1),
			height: "100%",
			backgroundColor: "rgba(166, 166, 166, .2)",
			overflowY: "auto",
			[theme.breakpoints.down("xs")]: {
				padding: 0
			}
		}
	});

export const PageBackground = withStyles(styles)(PageBackgroundBase);
