import { FC } from "react";
import {
	AppBar as MuiAppBar,
	Theme,
	WithStyles,
	Toolbar,
	Typography,
	IconButton,
	createStyles,
	withStyles,
	ButtonBase
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";

export interface AppBarProps {
	onMenuClick?: () => void;
	logoSrc?: string;
	onLogoClick?: () => void;
	title?: string;
}
const AppBarBase: FC<AppBarProps & WithStyles<typeof styles>> = ({
	logoSrc,
	onLogoClick,
	onMenuClick,
	title,
	classes
}) => {
	return (
		<MuiAppBar position="static">
			<Toolbar className={classes.toolbar}>
				<div>
					<ButtonBase onClick={onLogoClick}>
						<img src={logoSrc} className={classes.logo} alt="Logo" />
					</ButtonBase>

					{title && (
						<Typography variant="h6" color="inherit">
							{title}
						</Typography>
					)}
				</div>
				<div>
					{onMenuClick && (
						<IconButton color="inherit" aria-label="Menu" onClick={onMenuClick}>
							<Menu />
						</IconButton>
					)}
				</div>
			</Toolbar>
		</MuiAppBar>
	);
};

const styles = (theme: Theme) =>
	createStyles({
		logo: {
			cursor: "pointer",
			padding: 5,
			height: 56,
			[`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
				height: 48
			},
			[theme.breakpoints.up("sm")]: {
				height: 64
			},
			filter:
				"drop-shadow(0px 2px 1px rgba(0,0,0,0.2)) drop-shadow(0px 1px 1px rgba(0,0,0,0.14)) drop-shadow(0px 1px 3px rgba(0,0,0,0.12))"
		},
		toolbar: {
			display: "flex",
			justifyContent: "space-between"
		}
	});

export const AppBar = withStyles(styles)(AppBarBase);
