import { Role } from "car-rental-management-shared";

export interface Page {
	id: string /** The file name of the component. Home.ts = "Home" */;
	path: string /** The url path of the page */;
	exact: boolean;
	access?: Role[] /** Which roles will have access to it. By default everyone has access. */;
	requireLogin?: boolean;
}

export interface Pages {
	[key: string]: Page;
}

export const Login: Page = {
	id: "Login",
	path: "/login",
	exact: true
};

export const Home: Page = {
	id: "Home",
	path: "/",
	exact: true,
	requireLogin: true
};

export const Bookings: Page = {
	id: "Bookings",
	path: "/bookings",
	exact: false,
	requireLogin: true
};

export const Users: Page = {
	id: "Users",
	path: "/users",
	exact: false,
	requireLogin: true
};

export const Vehicles: Page = {
	id: "Vehicles",
	path: "/vehicles",
	exact: false,
	requireLogin: true
};

export const Locations: Page = {
	id: "Locations",
	path: "/locations",
	exact: false,
	requireLogin: true
};

export const Clients: Page = {
	id: "Clients",
	path: "/clients",
	exact: false,
	access: [Role.MASTER],
	requireLogin: true
};

export const Accidents: Page = {
	id: "Accidents",
	path: "/accidents",
	exact: false,
	requireLogin: true
};

export const Categories: Page = {
	id: "Categories",
	path: "/categories",
	exact: false,
	requireLogin: true
};

export const SignUp: Page = {
	id: "SignUp",
	path: "/signup",
	exact: false
};

export const Settings: Page = {
	id: "Settings",
	path: "/settings",
	exact: false,
	requireLogin: true
};

export const pages: Pages = {
	Home,
	Bookings,
	Users,
	Vehicles,
	Locations,
	Clients,
	Accidents,
	Categories,
	SignUp,
	Settings,
	Login
};
