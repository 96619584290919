import { Reducer } from "redux";
import { ApiState } from "../state";
import { SetApiAction, ActionType } from "../actions/types";

export const api: Reducer<ApiState, SetApiAction> = (state = null, action) => {
	if (action.type === ActionType.SET_API) {
		return action.payload;
	}
	return state;
};
