import { ApiState } from "../state";
import { SetApiAction, ActionType } from "./types";

export abstract class Api {
	public static set = (api: ApiState): SetApiAction => {
		return {
			type: ActionType.SET_API,
			payload: api
		};
	};
}
