import { Reducer } from "redux";
import { VehicleCategoriesState } from "../state";
import { ActionType, SetVehicleCategoriesAction } from "../actions/types";

export const vehicleCategories: Reducer<
	VehicleCategoriesState,
	SetVehicleCategoriesAction
> = (state = null, action) => {
	if (action.type === ActionType.SET_VEHICLE_CATEGORIES) {
		return action.payload;
	}
	return state;
};
