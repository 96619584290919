import { publicVapidKey } from "../config/push";
import { apiDomain } from "../config/paths";

export class PushNotification {
	public static publicVapidKey = publicVapidKey;

	/**
	 * Browser asks for notification permission.
	 * Does not show when the user already declines
	 * or approved.
	 */
	public static askUserPermission = () => {
		return Notification.requestPermission();
	};

	/**
	 * Browser supports displaying and polling push notifications.
	 */
	public static isSupported = () => {
		return "serviceWorker" in navigator && "PushManager" in window;
	};

	public static registerServiceWorker = () => {
		return navigator.serviceWorker.register("/push.worker.js", {
			scope: "/"
		});
	};

	public static getPushSubscription = async () => {
		const serviceWorker = await navigator.serviceWorker.ready;

		const existingSubscription = await serviceWorker.pushManager.getSubscription();
		if (existingSubscription) {
			return existingSubscription;
		}
		return null;
	};

	public static getAndSendPush = async () => {
		if (PushNotification.isSupported()) {
			const subscription = await PushNotification.getNotificationSubscription();
			await PushNotification.postSubscription(subscription);
		}
	};

	/**
	 * Sends push subscription data to the backend server.
	 */
	public static postSubscription = async (subscription: PushSubscription) => {
		const response = await fetch(
			`${apiDomain}/api/carbooking/push_notifications/subscriptions`,
			{
				credentials: "include",
				headers: {
					"content-type": "application/json;charset=UTF-8"
				},
				body: JSON.stringify(subscription),
				method: "POST",
				mode: "cors"
			}
		);
		return response.json();
	};

	/**
	 * Gets the existing push manager subscription.
	 * If not existing, creates a new one.
	 */
	public static getNotificationSubscription = async () => {
		const serviceWorker = await navigator.serviceWorker.ready;

		const existingSubscription = await serviceWorker.pushManager.getSubscription();
		if (existingSubscription) {
			return existingSubscription;
		}
		return serviceWorker.pushManager.subscribe({
			userVisibleOnly: true,
			applicationServerKey: PushNotification.urlBase64ToUint8Array(
				PushNotification.publicVapidKey
			)
		});
	};

	public static get permission() {
		return Notification.permission;
	}

	private static urlBase64ToUint8Array = (base64String: string) => {
		const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
		const base64 = (base64String + padding)
			.replace(/-/g, "+")
			.replace(/_/g, "/");

		const rawData = window.atob(base64);
		const outputArray = new Uint8Array(rawData.length);

		for (let i = 0; i < rawData.length; ++i) {
			outputArray[i] = rawData.charCodeAt(i);
		}
		return outputArray;
	};
}
