import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MessageModal } from "../presentational/modal/MessageModal";
import { Modal } from "../../store/actions/Modal";
import { StoreState } from "../../store/state";

export const ReduxMessageModal = () => {
	const { modal } = useSelector(({ modal }: StoreState) => ({ modal }));
	const dispatch = useDispatch();
	const dismissModal = useCallback(() => {
		dispatch(Modal.dismiss());
	}, [dispatch]);
	return (
		<MessageModal
			yesText="OK"
			yes={dismissModal}
			title={modal.title}
			open={modal.open}
			content={modal.content}
		/>
	);
};
