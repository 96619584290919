import { Reducer } from "redux";
import { AccidentsState } from "../state";
import { ActionType, SetAccidentsAction } from "../actions/types";

export const accidents: Reducer<AccidentsState, SetAccidentsAction> = (
	state = null,
	action
) => {
	if (action.type === ActionType.SET_ACCIDENTS) {
		return action.payload;
	}
	return state;
};
