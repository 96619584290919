import { ReactElement, ReactText, FC } from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link } from "react-router-dom";

export interface SideBarButtonProps {
	icon: ReactElement;
	text: ReactText;
	onClick?: () => void;
	path?: string;
}
export const SideBarButton: FC<SideBarButtonProps> = ({
	onClick,
	icon,
	text,
	path
}) => (
	// @ts-ignore because of @material-ui limitation.
	<ListItem component={path && Link} to={path} button onClick={onClick}>
		<ListItemIcon>{icon}</ListItemIcon>
		<ListItemText>{text}</ListItemText>
	</ListItem>
);
