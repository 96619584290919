import { Api } from "car-rental-management-api";
import {
	BookingServerResponseGet,
	VehicleServerResponseGet,
	LocationServerResponseGet,
	UserServerResponseGet,
	ClientServerResponseGet,
	AccidentServerResponseGet,
	CategoryServerResponseGet,
	VehicleCategoryAttributes,
	DatePropsToUnix,
	WialonUnitAttributes
} from "car-rental-management-shared";

export type ApiState = false | null | Api;

export type ModalState = {
	title: string;
	open: boolean;
	content: string;
};

export type BookingsState = BookingServerResponseGet["data"][] | null;
export type VehiclesState = VehicleServerResponseGet["data"][] | null;
export type LocationsState = LocationServerResponseGet["data"][] | null;
export type UsersState = UserServerResponseGet["data"][] | null;
export type ClientsState = ClientServerResponseGet["data"][] | null;
export type AccidentsState = AccidentServerResponseGet["data"][] | null;
export type CategoriesState = CategoryServerResponseGet["data"][] | null;
export type VehicleCategoriesState =
	| DatePropsToUnix<VehicleCategoryAttributes>[]
	| null;
export type WialonUnitState = WialonUnitAttributes[] | null;

export interface StoreState {
	api: ApiState;
	modal: ModalState;
	bookings: BookingsState;
	vehicles: VehiclesState;
	locations: LocationsState;
	users: UsersState;
	clients: ClientsState;
	accidents: AccidentsState;
	categories: CategoriesState;
	vehicleCategories: VehicleCategoriesState;
	wialonUnits: WialonUnitState;
}

export const INITIAL_STATE: StoreState = {
	api: null,
	modal: {
		title: "",
		open: false,
		content: ""
	},
	bookings: null,
	vehicles: null,
	locations: null,
	users: null,
	clients: null,
	accidents: null,
	categories: null,
	vehicleCategories: null,
	wialonUnits: null
};
