import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { Route, RouteComponentProps, useHistory } from "react-router";
import { Page, pages } from "../../config/pages";
import { StoreState } from "../../store/state";
import { LoadingIcon } from "../presentational/display/LoadingIcon";
import { PageBackground } from "../presentational/display/PageBackground";
import { AuthorizedRender } from "./AuthorizedRender";
import { DynamicComponent } from "./DynamicComponent";

export const PageList = () => {
	const api = useSelector((state: StoreState) => state.api);
	const fallback = useMemo(() => <LoadingIcon />, []);
	const history = useHistory();
	const renderPage = useCallback(
		(page: Page, props: RouteComponentProps) => {
			const pageComponent = (
				<DynamicComponent
					{...props}
					fallBack={
						<PageBackground>
							<LoadingIcon />
						</PageBackground>
					}
					load={import(`../pages/${page.id}`).then(p => p[page.id])}
				/>
			);

			if (page.requireLogin) {
				return (
					<AuthorizedRender
						api={api}
						access={page.access}
						fallback={fallback}
						onUnauthorized={() => history.push("/login")}
					>
						{pageComponent}
					</AuthorizedRender>
				);
			}
			return pageComponent;
		},
		[api, fallback, history]
	);
	return (
		<>
			{Object.values(pages).map(page => {
				return (
					<Route
						key={page.id}
						path={page.path}
						exact={page.exact}
						render={props => (
							<PageBackground>{renderPage(page, props)}</PageBackground>
						)}
					/>
				);
			})}
		</>
	);
};
