import { Reducer } from "redux";
import { ClientsState } from "../state";
import { ActionType, SetClientsAction } from "../actions/types";

export const clients: Reducer<ClientsState, SetClientsAction> = (
	state = null,
	action
) => {
	if (action.type === ActionType.SET_CLIENTS) {
		return action.payload;
	}
	return state;
};
