import { FC } from "react";
import {
	Drawer,
	withStyles,
	createStyles,
	WithStyles
} from "@material-ui/core";

export interface SideBarProps {
	isOpen: boolean;
	onClose: () => void;
}

const SideBarBase: FC<SideBarProps & WithStyles<typeof styles>> = ({
	isOpen,
	onClose,
	children,
	classes
}) => {
	return (
		<Drawer open={isOpen} onClose={onClose}>
			<div className={classes.drawer}>{children}</div>
		</Drawer>
	);
};

const styles = createStyles({
	drawer: {
		width: 250,
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		minHeight: "100vh"
	}
});

export const SideBar = withStyles(styles)(SideBarBase);
