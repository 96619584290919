import { FC, ReactNode } from "react";
import { DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { ModalProps, Modal } from "./Modal";

export interface ContentModalProps extends ModalProps {
	title?: string;
	disabled?: boolean;
	actions?: ReactNode;
}

export const ContentModal: FC<ContentModalProps> = ({
	title,
	disabled,
	children,
	actions,
	...modalProps
}) => {
	return (
		<Modal {...modalProps}>
			{title && <DialogTitle>{title}</DialogTitle>}
			<DialogContent>{children}</DialogContent>
			{actions && <DialogActions>{actions}</DialogActions>}
		</Modal>
	);
};
