import { Component } from "react";
import { compose } from "@utils/FunctionalUtils";
import { withRouter, RouteChildrenProps } from "react-router-dom";
import { MapStateToProps, connect } from "react-redux";
import { AuthServerResponseGet, Role } from "car-rental-management-shared";
import { StringUtils } from "car-rental-management-utils";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import { AppBar } from "../presentational/appBar/AppBar";
import { SideBar } from "../presentational/sideBar/SideBar";
import {
	SideBarProfile,
	SideBarProfileProps
} from "../presentational/sideBar/SideBarProfile";
import { SideBarList } from "../presentational/sideBar/SideBarList";
import {
	SideBarButton,
	SideBarButtonProps
} from "../presentational/sideBar/SideBarButton";
import { sidebarItems } from "../../config/sidebar-items";
import { appLogoUrl } from "../../config/paths";
import { StoreState } from "../../store/state";
import { Api } from "../../store/actions/Api";
import { PushNotification } from "../../utils/PushNotification";

interface AppBarWithSideBarState {
	isOpen: boolean;
}

type AppBarWithSideBarStateProps = Pick<StoreState, "api"> & {
	role?: Role;
};

type AppBarWithSideBarActionProps = { setApi: typeof Api.set };

export type AppBarWithSideBarProps = RouteChildrenProps;

type Props = AppBarWithSideBarStateProps &
	AppBarWithSideBarActionProps &
	AppBarWithSideBarProps;

class AppBarWithSideBarBase extends Component<Props, AppBarWithSideBarState> {
	constructor(props: Props) {
		super(props);
		this.state = {
			isOpen: false
		};
	}

	private getListItems = () => {
		const { role } = this.props;
		const sideBarList = sidebarItems.reduce<{
			top: SideBarButtonProps[];
			bottom: SideBarButtonProps[];
		}>(
			(list, sideBarItem) => {
				const item: SideBarButtonProps = {
					icon: <sideBarItem.icon />,
					text: sideBarItem.title,
					path: sideBarItem.page.path,
					onClick: () => this.setState({ isOpen: false })
				};
				// If user role passes the required role, or if it does not require a role.
				if (
					(role && sideBarItem.role && sideBarItem.role.includes(role)) ||
					sideBarItem.role === undefined
				) {
					if (sideBarItem.location === "top") {
						list.top.push(item);
					} else {
						list.bottom.push(item);
					}
				}
				return list;
			},
			{ top: [], bottom: [] }
		);
		// Add logout button.
		sideBarList.bottom.push({
			icon: <LogoutIcon />,
			text: "Logout",
			onClick: async () => {
				try {
					if (this.props.api) {
						if (PushNotification.isSupported()) {
							const pushSubscription = await PushNotification.getPushSubscription();
							if (pushSubscription) {
								const { endpoint } = pushSubscription;
								await this.props.api.unsubscribePush({
									endpoint
								});
							}
						}
						await this.props.api.logout();
						this.props.setApi(false);
						this.props.history.push("/");
					}
				} catch (e) {
					console.error(e.message);
				}
			}
		});
		return sideBarList;
	};

	private getInitials = (firstName: string, lastName: string) => {
		const firstLetter = firstName[0];
		const lastLetter = lastName[0];
		return `${firstLetter || ""}${lastLetter || ""}`;
	};

	private getProfileValues = (
		userData: AuthServerResponseGet["data"]
	): SideBarProfileProps => {
		const { firstName, lastName, role, userImageSrc } = userData;
		return {
			imgSrc: userImageSrc || undefined,
			initials: this.getInitials(firstName, lastName),
			subtitle: StringUtils.toTitleWords(role),
			title: `${firstName} ${lastName}`,
			bottomDivider: true
		};
	};

	public render() {
		const { top, bottom } = this.getListItems();
		return (
			<>
				<AppBar
					onMenuClick={
						(this.props.api &&
							(() =>
								this.setState(state => ({
									isOpen: !state.isOpen
								})))) ||
						undefined
					}
					logoSrc={appLogoUrl}
					onLogoClick={() => this.props.history.push("/")}
				/>
				{this.props.api && (
					<SideBar
						isOpen={this.state.isOpen}
						onClose={() => this.setState({ isOpen: false })}
					>
						<div>
							<SideBarProfile {...this.getProfileValues(this.props.api.data)} />
							<SideBarList bottomDivider>
								{top.map(props => (
									<SideBarButton
										key={`${props.path || ""}${props.text}`}
										{...props}
									/>
								))}
							</SideBarList>
						</div>
						<SideBarList topDivider>
							{bottom.map(props => (
								<SideBarButton
									key={`${props.path || ""}${props.text}`}
									{...props}
								/>
							))}
						</SideBarList>
					</SideBar>
				)}
			</>
		);
	}
}

const mapStateToProps: MapStateToProps<
	AppBarWithSideBarStateProps,
	Props,
	StoreState
> = ({ api }) => {
	const role = (api && api.data.role) || undefined;
	return { api, role };
};

export const AppBarWithSideBar = compose<Props, AppBarWithSideBarProps>(
	connect(mapStateToProps, { setApi: Api.set }),
	withRouter
)(AppBarWithSideBarBase);
