import { Reducer } from "redux";
import { BookingsState } from "../state";
import { ActionType, SetBookingsAction } from "../actions/types";

export const bookings: Reducer<BookingsState, SetBookingsAction> = (
	state = null,
	action
) => {
	if (action.type === ActionType.SET_BOOKINGS) {
		return action.payload;
	}
	return state;
};
