import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { PushNotification } from "./utils/PushNotification";

if (PushNotification.isSupported()) {
	PushNotification.registerServiceWorker();
}

ReactDOM.render(<App />, document.getElementById("root"));
