import { FC } from "react";
import { DialogContentText, Button } from "@material-ui/core";
import { ContentModalProps, ContentModal } from "./ContentModal";

interface MessageModalProps extends Omit<ContentModalProps, "onClose"> {
	yes?: () => void;
	no?: () => void;
	yesText?: string;
	noText?: string;
	title?: string;
	content?: string;
	disabled?: boolean;
}

export const MessageModal: FC<MessageModalProps> = ({
	yes,
	no,
	title,
	content,
	disabled,
	yesText = "Yes",
	noText = "No",
	...modalProps
}) => {
	return (
		<ContentModal
			actions={
				<>
					{yes && (
						<Button
							onClick={yes}
							disabled={disabled}
							color="primary"
							variant="contained"
						>
							{yesText}
						</Button>
					)}
					{no && (
						<Button
							onClick={no}
							disabled={disabled}
							color="primary"
							variant="contained"
							autoFocus
						>
							{noText}
						</Button>
					)}
				</>
			}
			onClose={no}
			{...modalProps}
			title={title}
		>
			{content && <DialogContentText>{content}</DialogContentText>}
		</ContentModal>
	);
};
