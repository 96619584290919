import { Reducer } from "redux";
import { LocationsState } from "../state";
import { ActionType, SetLocationsAction } from "../actions/types";

export const locations: Reducer<LocationsState, SetLocationsAction> = (
	state = null,
	action
) => {
	if (action.type === ActionType.SET_LOCATIONS) {
		return action.payload;
	}
	return state;
};
