import { ComponentType } from "react";
import HomeIcon from "@material-ui/icons/Home";
import BookingIcon from "@material-ui/icons/ChromeReaderMode";
import UserIcon from "@material-ui/icons/SupervisedUserCircle";
import VehicleIcon from "@material-ui/icons/DirectionsCar";
import ClientIcon from "@material-ui/icons/People";
import AccidentIcon from "@material-ui/icons/Warning";
import LocationIcon from "@material-ui/icons/Place";
import CategoryIcon from "@material-ui/icons/Category";
import SettingsIcon from "@material-ui/icons/Settings";
import { Role } from "car-rental-management-shared";
import {
	Page,
	Home,
	Bookings,
	Users,
	Vehicles,
	Clients,
	Accidents,
	Locations,
	Categories,
	Settings
} from "./pages";

interface SidebarItem {
	page: Page;
	location: "top" | "bottom";
	title: string;
	icon: ComponentType;
	role?: Role[];
}

export const sidebarItems: SidebarItem[] = [
	{
		page: Home,
		location: "top",
		title: "Home",
		icon: HomeIcon
	},
	{
		page: Bookings,
		location: "top",
		title: "Bookings",
		icon: BookingIcon
	},
	{
		page: Users,
		location: "top",
		title: "Users",
		icon: UserIcon,
		role: [Role.MASTER, Role.ADMIN, Role.KEY_MANAGER]
	},
	{
		page: Vehicles,
		location: "top",
		title: "Vehicles",
		icon: VehicleIcon
	},
	{
		page: Locations,
		location: "top",
		title: "Locations",
		icon: LocationIcon
	},
	{
		page: Clients,
		location: "top",
		title: "Clients",
		icon: ClientIcon,
		role: [Role.MASTER]
	},
	{
		page: Accidents,
		location: "top",
		title: "Accidents",
		icon: AccidentIcon,
		role: [Role.MASTER, Role.ADMIN, Role.KEY_MANAGER]
	},
	{
		page: Accidents,
		location: "top",
		title: "Accidents",
		icon: AccidentIcon,
		role: [Role.GUEST]
	},
	{
		page: Categories,
		location: "top",
		title: "Categories",
		icon: CategoryIcon,
		role: [Role.MASTER, Role.ADMIN, Role.KEY_MANAGER]
	},
	{
		page: Settings,
		location: "top",
		title: "Settings",
		icon: SettingsIcon
	}
];
