import { FC } from "react";
import {
	Avatar,
	Typography,
	Divider,
	Theme,
	WithStyles,
	createStyles,
	withStyles
} from "@material-ui/core";

export interface SideBarProfileProps {
	bottomDivider?: boolean;
	imgSrc?: string;
	alt?: string;
	initials: string;
	title: string;
	subtitle: string;
}
export const SideBarProfileBase: FC<
	SideBarProfileProps & WithStyles<typeof styles>
> = ({ bottomDivider, imgSrc, alt, classes, initials, title, subtitle }) => {
	return (
		<>
			<div className={classes.root}>
				{imgSrc ? (
					<Avatar
						alt={alt || "Profile"}
						src={imgSrc}
						className={classes.image}
					/>
				) : (
					<Avatar className={classes.image}>{initials}</Avatar>
				)}
				<div>
					<Typography component="h2" variant="subtitle1">
						{title}
					</Typography>
					<Typography component="h3" variant="caption">
						{subtitle}
					</Typography>
				</div>
			</div>
			{bottomDivider && <Divider />}
		</>
	);
};

const styles = (theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			margin: theme.spacing(2),
			alignItems: "center"
		},
		image: {
			marginRight: theme.spacing(1)
		}
	});

export const SideBarProfile = withStyles(styles)(SideBarProfileBase);
