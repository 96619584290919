import { ModalState } from "../state";
import { DismissModalAction, ActionType, CreateModalAction } from "./types";

export abstract class Modal {
	public static dismiss = (): DismissModalAction => {
		return {
			type: ActionType.DISMISS_MODAL
		};
	};

	public static create = (
		options: Omit<ModalState, "open">
	): CreateModalAction => {
		return {
			type: ActionType.CREATE_MODAL,
			payload: options
		};
	};
}
