import { combineReducers } from "redux";
import { api } from "./api";
import { modal } from "./modal";
import { bookings } from "./bookings";
import { vehicles } from "./vehicles";
import { locations } from "./locations";
import { users } from "./users";
import { clients } from "./clients";
import { accidents } from "./accidents";
import { vehicleCategories } from "./vehicleCategories";
import { categories } from "./categories";
import { wialonUnits } from "./wialonUnits";
import { StoreState } from "../state";

export const reducers = combineReducers<StoreState>({
	api,
	modal,
	bookings,
	vehicles,
	locations,
	users,
	clients,
	accidents,
	vehicleCategories,
	categories,
	wialonUnits
});
