import { Action as ReduxAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
	ApiState,
	ModalState,
	BookingsState,
	AccidentsState,
	ClientsState,
	UsersState,
	LocationsState,
	VehiclesState,
	CategoriesState,
	VehicleCategoriesState,
	StoreState,
	WialonUnitState
} from "../state";

export enum ActionType {
	SET_API = "SET_API",
	CREATE_MODAL = "CREATE_MODAL",
	DISMISS_MODAL = "DISMISS_MODAL",
	SET_BOOKINGS = "SET_BOOKINGS",
	SET_VEHICLES = "SET_VEHICLES",
	SET_LOCATIONS = "SET_LOCATIONS",
	SET_USERS = "SET_USERS",
	SET_CLIENTS = "SET_CLIENTS",
	SET_ACCIDENTS = "SET_ACCIDENTS",
	SET_CATEGORIES = "SET_CATEGORIES",
	SET_VEHICLE_CATEGORIES = "SET_VEHICLE_CATEGORIES",
	SET_WIALON_UNIT = "SET_WIALON_UNIT"
}

/**
 * T is type of action. P is payload.
 */
export interface Action<T, P> extends ReduxAction<T> {
	payload: P;
}

export type SetApiAction = Action<ActionType.SET_API, ApiState>;

export type DismissModalAction = ReduxAction<ActionType.DISMISS_MODAL>;

export type CreateModalAction = Action<
	ActionType.CREATE_MODAL,
	Omit<ModalState, "open">
>;

export type SetBookingsAction = Action<ActionType.SET_BOOKINGS, BookingsState>;
export type SetVehiclesAction = Action<ActionType.SET_VEHICLES, VehiclesState>;
export type SetLocationsAction = Action<
	ActionType.SET_LOCATIONS,
	LocationsState
>;
export type SetUsersAction = Action<ActionType.SET_USERS, UsersState>;
export type SetClientsAction = Action<ActionType.SET_CLIENTS, ClientsState>;
export type SetAccidentsAction = Action<
	ActionType.SET_ACCIDENTS,
	AccidentsState
>;
export type SetCategoriesAction = Action<
	ActionType.SET_CATEGORIES,
	CategoriesState
>;
export type SetVehicleCategoriesAction = Action<
	ActionType.SET_VEHICLE_CATEGORIES,
	VehicleCategoriesState
>;
export type SetWialonUnitsAction = Action<
	ActionType.SET_WIALON_UNIT,
	WialonUnitState
>;

export type AllActions =
	| SetApiAction
	| DismissModalAction
	| CreateModalAction
	| SetBookingsAction
	| SetVehiclesAction
	| SetLocationsAction
	| SetUsersAction
	| SetClientsAction
	| SetAccidentsAction
	| SetCategoriesAction
	| SetVehicleCategoriesAction;

export type AppDispatch = ThunkDispatch<StoreState, any, AllActions>;
