import { Reducer } from "redux";
import { ModalState, INITIAL_STATE } from "../state";
import {
	CreateModalAction,
	DismissModalAction,
	ActionType
} from "../actions/types";

export const modal: Reducer<
	ModalState,
	CreateModalAction | DismissModalAction
> = (state = INITIAL_STATE.modal, action) => {
	if (action.type === ActionType.CREATE_MODAL) {
		return { ...state, ...action.payload, open: true };
	}
	if (action.type === ActionType.DISMISS_MODAL) {
		return { ...state, open: false };
	}
	return state;
};
