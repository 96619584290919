import { FC } from "react";
import "typeface-roboto";
import { BrowserRouter, Route } from "react-router-dom";
import {
	withStyles,
	createStyles,
	Theme,
	WithStyles,
	MuiThemeProvider,
	createMuiTheme
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { ReduxMessageModal } from "./components/container/ReduxMessageModal";
import { reducers } from "./store/reducers";
import { INITIAL_STATE } from "./store/state";
import { All } from "./components/pages/All";
import { PageList } from "./components/utils/PageList";

const store = createStore(reducers, INITIAL_STATE, applyMiddleware(thunk));
const theme = createMuiTheme();

const App: FC<WithStyles<typeof styles>> = ({ classes }) => {
	return (
		<div className={classes.app}>
			<MuiThemeProvider theme={theme}>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<Provider store={store}>
						<CssBaseline />
						<BrowserRouter>
							<Route path="/" exact={false} component={All} />
							<PageList />
							<ReduxMessageModal />
						</BrowserRouter>
					</Provider>
				</MuiPickersUtilsProvider>
			</MuiThemeProvider>
		</div>
	);
};

const styles = (theme: Theme) =>
	createStyles({
		"@global": {
			body: {
				background: "url(/static/images/wallpaper.svg)  center center fixed",
				height: "100%"
			},
			html: {
				height: "100%"
			},
			"#root": { height: "100%" },

			/* width */
			"::-webkit-scrollbar": {
				width: "5px",
				height: "5px"
			},
			/* Track */
			"::-webkit-scrollbar-track": {
				background: "rgba(255, 255, 255, 0.1)",
				borderRadius: "1000px"
			},
			/* Handle */
			"::-webkit-scrollbar-thumb": {
				background: "#888",
				borderRadius: "1000px"
			},
			/* Handle on hover */
			"::-webkit-scrollbar-thumb:hover": {
				background: "linear-gradient(180deg, #FF8E53 30%, #FE6B8B 90%)"
			}
		},
		app: {
			display: "flex",
			flexDirection: "column",
			height: "100%"
		},
		body: {
			padding: theme.spacing(2),
			margin: theme.spacing(1),
			height: "100%",
			backgroundColor: "rgba(166, 166, 166, .2)",
			overflowY: "auto",
			[theme.breakpoints.down("xs")]: {
				padding: 0
			}
		}
	});

export default withStyles(styles)(App);
