import { ComponentType } from "react";
import loadable from "@loadable/component";

interface DynamicComponentProps<Props extends object> {
	load: Promise<ComponentType<Props>>;
	fallBack?: JSX.Element;
}
/**
 * Enables code splitting on apps for increased performance.
 */
export const DynamicComponent = <Props extends object = {}>(
	props: Props & DynamicComponentProps<Props>
) => {
	const { load, fallBack, ...otherProps } = props;
	const DynamicComponent = loadable<Props>(() => load);

	return <DynamicComponent {...(otherProps as Props)} fallback={fallBack} />;
};
